.login-wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-form-wrapper{
  padding: 50px;
  border-radius: 5px;
  background: #f2f2f2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  /* transform: translate3D(0,-1px,0) scale(1.02);
  transition: all .4s ease; */
}
.login-wrapper .login-form-wrapper .header{
  margin-bottom: 20px;
}
.login-wrapper .login-form-wrapper .body{
  margin-top: 20px;
  text-align: center;
}

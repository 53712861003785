.moderation-filter-wrapper {
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.moderation-filter-wrapper .search-input {
  width: 250px;
}

.moderation-filter-wrapper .moderation-select-filter {
  padding: 0 12px;
}

.moderation-filter-wrapper .ant-select-selection {
  width: 150px;
}

.moderation-filter-wrapper .ant-calendar-picker,
.comment-page .ant-calendar-picker {
  width: 210px;
}

.moderation-wrapper > .ant-divider.ant-divider-horizontal {
  margin: 0;
}

.moderation-wrapper .ml-auto {
  margin-left: auto;
}

.post-list-wrapper {
  height: 100%;
  padding: 12px 0px 12px 24px;
  position: relative;
}

.post-list-wrapper .virtuoso {
  height: 100% !important;
  overflow-x: hidden;
  margin-right: 2px;
}

.post-list-wrapper .post,
.comment-page .post {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  padding: 12px;
  background: #fff;
  margin: 1px 12px 12px 1px;
}

.post.selected-post {
  border: 2px solid black;
}

.post-list-wrapper .post-list-spinner {
  font-size: 48px;
}

.comment-list-wrapper .post-list-spinner {
  font-size: 48px;
}

.post-list-wrapper .bottom-fetch-more-loader {
  position: absolute;
  bottom: 20px;
}

.reference-data-container .post-share-details {
  padding: 12px;
}

.comment-list-wrapper .bottom-fetch-more-loader {
  position: absolute;
  bottom: 20px;
}

.post .post-user-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  word-break: break-word;
}

.post .post-user-action-icons span {
  font-size: 12px;
  line-height: 15px;
}

.post .post-user-action-icons i {
  font-size: 14px;
}

.post .post-action-icons span {
  font-size: 10px;
}

.post .post-action-icons i {
  font-size: 12px;
}

.post .post-create-time {
  font-size: 12px;
  color: #c1bdbd;
}

.post .post-text {
  margin-top: 14px !important;
  color: #262626 !important;
  word-break: break-word !important;
}

.post .video-container {
  position: relative;
}

.post-video-modal .video-react {
  padding-top: 56.25% !important;
}

.post button.video-play-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  color: white;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
}

.post .video-play-button:hover {
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
}

.post .video-gif {
  width: 100%;
  max-height: 350px;
  object-fit: contain;
  background-color: black;
}

.post .post-page-media-container {
  max-width: 100%;
  max-height: 350px;
  min-height: 100%;
}

.post-page-media-container > img {
  max-width: 100%;
  min-width: 100%;
  max-height: 350px;
  min-height: 100%;
  object-fit: contain;
}

.post-page-media-container > .video-react {
  padding-top: 56.25% !important;
}

.post .reference-data-container {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  /* padding: 12px; */
}

.reference-data-container img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}

.reference-data-container .reference-data-details {
  padding: 4px 8px;
}

.reference-data-details .reference-data-title {
  font-weight: bold;
  font-size: "14px";
  margin-bottom: 0px;
}

.post-page-divider {
  margin: 12px 0 !important;
}

.comment-list-wrapper,
.post-edit {
  /* overflow: auto; */
  height: 100%;
  padding: 12px;
  margin-left: 12px;
  background: #fff;
  position: relative;
}

.post-edit #edit-post-form {
  overflow: auto;
  height: 100%;
  padding: 12px 12px 0px 6px;
  display: flex;
  flex-direction: column;
}

.post-edit-reference-data {
  padding: 20px;
  border: 1px dotted black;
  position: relative;
}

.post-edit-reference-remove-upload {
  position: absolute;
  top: -5px;
  right: 0px;
  font-size: 24px;
  color: #d90000;
}

.post-edit .edit-media-wrapper {
  position: relative;
  padding: 20px;
  border: 1px dotted black;
}

.post-edit .btn-media-remove {
  position: absolute;
  top: -5px;
  right: 0px;
  color: #d90000;
  z-index: 999;
  font-size: 24px;
}

#edit-post-form .ant-upload-list-item-progress .ant-progress-outer {
  display: none;
}

.comment-wrapper {
  height: 100%;
  padding: 12px 12px 0px 6px;
  margin-bottom: 12px;
}

.comment-wrapper .virtuoso {
  height: 100% !important;
}

.replay-wrapper {
  height: calc(100% - 75px);
  padding: 12px 12px 0px 6px;
  margin-bottom: 12px;
}

.replay-wrapper .virtuoso {
  height: 100% !important;
}

.comment-list-wrapper .replay-header {
  margin-bottom: 12px;
}

.comment-list-wrapper .comment,
.live-comment-list .comment {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  padding: 12px;
  margin: 12px 12px;
}

.comment .comment-text {
  margin-top: 14px;
  color: #262626;
  word-break: break-word !important;
}

.comment .moderator-action-wrapper {
  margin-top: 14px;
}

.comment .comment-media {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  margin-bottom: 12px;
}

.comment .star-wrapper {
  margin-right: 6px;
  color: #999999;
}

.comment .star-icon-colored {
  color: #ebd45c;
}

.comment .comment-action-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
}

.comment .moderator-action-list {
  padding-left: 20px;
  color: #8c8c8c;
}

.comment .comment-moderation-icons span {
  font-size: 10px;
  line-height: 15px;
  color: #595959;
}

.comment .comment-create-time {
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.comment .comment-user-name {
  font-weight: bold;
  word-break: break-word !important;
}

.ml-auto {
  margin-left: auto;
}
.comment-page .moderation-filter-wrapper .ant-switch-checked {
  background-color: #d90000;
}

.comment-page > .ant-divider.ant-divider-horizontal.moderation-filter-divider {
  margin: 0;
}

.comment-page .comment-list {
  height: 100%;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
}

.comment-list .virtuoso {
  height: 100% !important;
  overflow-x: hidden;
  margin-right: 2px;
  padding: 2px;
}

.flex-direction-column {
  flex-direction: column;
}

.moderation-action-btn,
.post .post-hidden-action-btn {
  color: white !important;
  background-color: #595959 !important;
  border-color: #595959 !important;
}

.comment-list button {
  overflow: hidden !important;
}

.comment-action-btn-wrapper button {
  overflow: hidden;
}

.comment-box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 12px;
  background: #fff;
  margin: 1px 12px 12px 1px !important;
}

.comment-box.selected {
  border: 1px solid black;
}

.comment-page .comment-action-btn-container {
  gap: 8px;
  align-items: stretch;
}

.comment-box .comment-user-details {
  margin-top: auto;
  padding: 12px 0;
}

.comment-box .comment-text {
  color: #262626;
  word-break: break-word !important;
}

.comment-user-details .comment-user-name {
  font-weight: bold;
  word-break: break-word !important;
}

.comment-user-details .comment-create-date {
  color: #c1bdbd;
  font-size: 12px;
}

.comment-user-details .comment-actions-icons span,
.sidecomment .moderation-score-icon span,
.sidecomment .comment-actions-icons span,
.comment .comment-actions-icons span {
  font-size: 10px;
  line-height: 15px;
}

.comment-user-details .comment-actions-icons i,
.sidecomment .moderation-score-icon i,
.sidecomment .comment-actions-icons i .comment .comment-actions-icons i {
  font-size: 12px;
}

.comment .comment-actions-icons .replay-count-wrapper {
  font-size: 12px;
}

.comment-user-details .comment-actions-icons .star-icon-gray {
  color: gray;
}

.sidecomment .comment-create-date,
.comment .comment-create-date {
  margin-left: 5px;
  color: #c1bdbd;
  font-size: 12px;
}

.sidecomment .show-comment-details {
  display: block;
}

.sidecomment .hide-comment-details {
  display: none;
}

.moderation-tag-wrapper {
  flex-wrap: wrap;
  gap: 6px;
}

.moderation-tag-wrapper .ant-tag {
  font-size: 10px;
  line-height: 16px;
}

.comment-box .comment-media {
  max-width: 100%;
  width: 350px;
  max-height: 250px;
  object-fit: contain;
  object-position: left;
}

.comment-box .comment-media-wrapper {
  margin: 12px 0;
}

.bottom-fetch-more-loader {
  position: absolute;
  bottom: 30px;
}

.top-fetch-more-loader {
  position: absolute;
  top: 30px;
}

.bottom-fetch-more-loader .moderation-spinner-size,
.top-fetch-more-loader .moderation-spinner-size {
  font-size: 48px;
}

.comment-page .sidecomment {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  padding: 12px;
  margin-bottom: 12px;
}

.comment-page .parent-comment-wrapper {
  padding: 0 1em;
}

.parent-comment-wrapper .sidecomment {
  background-color: #f5f5f5 !important;
}

.comment-page .post-wrapper {
  background-color: white;
  overflow-y: auto;
  padding: 12px;
  margin-left: 12px;
  height: calc(100vh - 64px - 64px - 10px);
}

.post-wrapper .side-comment-list-wrapper {
  padding: 1em 12px;
}

.height-0 {
  height: 0 !important;
}

.side-comment-list-wrapper .comment.sidecomment {
  margin: 2px 12px 12px 2px;
}

.comment.sidecomment.is-deleted,
.comment.is-deleted,
.comment-box.is-deleted {
  background-color: #ffefef !important;
}

.live-post-wrapper {
  background-color: white;
  overflow-y: auto;
  padding: 1em;
  height: 100%;
}

.live-post-video-wrapper {
  position: relative;
}

.live-post-video-tag {
  position: absolute;
  top: 20px;
  left: 30px;
}

.live-comment-list {
  position: relative;
  height: calc(100vh - 350px);
  padding: 1em;
}

.live-comment-list .virtuoso {
  height: 100% !important;
  overflow-x: hidden;
}

.live-comments-search-input {
  margin-bottom: 1em;
}

.align-self-center {
  align-self: center;
}

.live-video-action-wrapper {
  padding-top: 1em;
  color: #595959;
}

.live-video-action-wrapper .ant-switch-checked {
  background-color: #d90000;
}

.moderation-filter-wrapper .go-live-header-btn,
.moderation-filter-wrapper .go-live-header-btn:active {
  background-color: #d90000 !important;
  color: #fff !important;
  border-color: #d90000 !important;
}

.flex-1 {
  flex: 1;
}

.height-100 {
  height: 100% !important;
}

.post .live-time {
  font-size: 12px;
  line-height: 15px;
}

.post .live-post-action-icon {
  font-size: 12px;
  line-height: 15px;
}

.comment-text.live-comment-text {
  color: #262626;
  word-break: break-word !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.align-self-flexstart {
  align-self: flex-start;
}

.post-video-modal .ant-modal-content .ant-modal-close-x i {
  margin-left: 1em;
  vertical-align: 0.7em;
} 
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900");

#root {
  height: 100vh;
  font-family: "Montserrat";
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}
.content-wrapper.ant-layout-content {
  max-height: 100vh;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;
}
.fullscreen {
  height: 100%;
  background: #f0f3f5;
}
img {
  max-width: 100%;
}
.d-flex {
  display: flex;
}
.box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  /* margin-bottom: 1rem; */
  padding: 1rem;
  background: #fff;
}
.text-center {
  text-align: center;
}
.border-bottom {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0.5em;
}
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callback-wrapper {
  width: 100%;
}
.callback-wrapper .loader-wrapper {
  height: 100vh;
}
.ant-table-row:nth-child(even),
.ant-table-thead > tr > th {
  background: #eeeeee;
}
.ant-table-row:nth-child(odd) {
  background: #f6f6f6;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
  line-height: 1;
  margin-bottom: 16px;
}

/* EPISODE STAUS */
.ant-tag.IDLE {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag.DRAFT {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag.SCHEDULED,
.ant-tag.RECORDING,
.ant-tag.UPCOMING {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag.LIVE,
.ant-tag.OPEN,
.ant-tag.LIVE_FINISHED,
.ant-tag.CONNECTED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag.PUBLISHED {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag.UNPUBLISHED,
.ant-tag.DISCONNECTED,
.ant-tag.ENDED,
.ant-tag.ERROR {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
/* END - EPISODE STAUS */

.ant-calendar-time-picker-btn {
  text-transform: capitalize;
}
.ant-calendar-ok-btn {
  text-transform: uppercase;
}
/* Upload */
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  padding-left: 80px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 80px;
}
/* END - Upload */

/* FONT WEIGHT */
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
/* END - FONT WEIGHT */

/* 404 */
.wrapper-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.wrapper-404 .title-404 {
  font-size: 10rem;
  font-weight: 700;
}
.wrapper-404 .subtitle-404 {
  font-weight: 700;
  font-size: 200%;
  margin-bottom: 2rem;
  line-height: 1.2;
}
.wrapper-404 .description-404 {
  margin-bottom: 10rem;
}
/* END - 404 */

/* FullScreen */
.fullscreen-mode aside,
.fullscreen-mode .header {
  display: none;
}
.fullscreen-mode .fullscreen {
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: 0px !important;
  max-width: none !important;
  min-height: 0px !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  margin: 0px !important;
}
/* END - Fullscreen */

.break-word {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.m-2 {
  margin: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-5 {
  margin-top: 0.8rem;
}

.p-2 {
  padding: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.p-0 {
  padding: 0;
}

.line-height-1 {
  line-height: 1.5;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ant-tag.IN_PROGRESS {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag.SUCCEED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-page-header.breakword {
  display: flex;
}

.ant-page-header.breakword .ant-page-header-heading {
  width: calc(100% - 220px);
}

.ant-page-header.breakword .ant-page-header-heading-title {
  word-break: break-all;
  display: inline;
}

.text-align-left {
  text-align: left !important;
}

.ant-page-header.breakword {
  display: flex;
}

.ant-page-header.breakword .ant-page-header-heading {
  width: calc(100% - 220px);
}

.ant-page-header.breakword .ant-page-header-heading-title {
  word-break: break-all;
  display: inline;
}

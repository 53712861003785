@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900);
.ant-layout {
  height: 100vh;
}
.ant-layout-header.header {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6 !important;
  padding: 0 24px;
}
.content-wrapper {
  /* background: #F2F2F2; */
  background: #f0f3f5;
}
.logo {
  font-size: 20px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  cursor: pointer;
}
.menu-divider-space {
  padding: 0 20px;
}
.menu-divider {
  border-right: 1px dotted silver;
}
.menu-divider.notification {
  text-align: center;
  font-size: 20px;
}
.menu-divider.notification i {
  font-size: 20px;
}
.header .user a {
  color: inherit;
}
.ant-menu-vertical > .ant-menu-item.menu-list,
.ant-menu-vertical-left > .ant-menu-item.menu-list,
.ant-menu-vertical-right > .ant-menu-item.menu-list,
.ant-menu-inline > .ant-menu-item.menu-list {
  height: 40px;
  line-height: 40px;
  font-size: 17px;
  margin-top: 0px;
}
.ant-menu-vertical > .ant-menu-item.menu-list i,
.ant-menu-vertical-left > .ant-menu-item.menu-list i,
.ant-menu-vertical-right > .ant-menu-item.menu-list i,
.ant-menu-inline > .ant-menu-item.menu-list i {
  font-size: 17px;
}

.anticon.custom-discussions {
  -webkit-transform: translate3D(0, -1px, 0) scale(1.33);
          transform: translate3D(0, -1px, 0) scale(1.33);
}

.anticon.custom-discussions svg {
  height: 14px;
}

.ant-menu-item:hover .anticon.custom-discussions {
  fill: #1890ff;
}

.ant-menu-item:hover:not(.ant-menu-item-selected) .discussion-icon,
.ant-menu-item:hover:not(.ant-menu-item-selected) .subscriber-icon {
  -webkit-filter: invert(0.4) sepia(1) saturate(5) hue-rotate(175deg);
          filter: invert(0.4) sepia(1) saturate(5) hue-rotate(175deg);
}

.ant-menu-item-selected .anticon.custom-discussions {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.ant-menu-item-selected .anticon.anticon.custom-users {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.ant-menu-submenu.menu-list {
  position: absolute;
  bottom: 48px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.ant-menu-submenu.menu-list .ant-menu-submenu-title {
  font-size: 17px;
  line-height: 40px;
}

.ant-menu-submenu.menu-list .ant-menu-submenu-title > div > span {
  margin-right: 0.5em;
  margin-left: -0.5em;
}

.ant-menu.moderationMenu .moderationMenuItem {
  height: 64px;
  line-height: 64px;
  font-size: 16px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.menu-list {
  background-color: #d90000 !important;
}

.ant-menu-item-selected.menu-list > a,
.ant-menu-item-selected.menu-list > a:hover {
  color: #ffffff !important;
}

.ant-menu-item-selected.menu-list .anticon.custom-discussions {
  fill: #ffffff !important;
}

.ant-menu-item.menu-list::after {
  border-right: 3px solid #d90000 !important;
}

.ant-menu-horizontal.moderationMenu > .ant-menu-item-selected > a,
.ant-menu-horizontal.moderationMenu > .ant-menu-item > a:hover {
  color: #d90000 !important;
}

.ant-menu-horizontal.moderationMenu > .ant-menu-item-selected,
.ant-menu-horizontal.moderationMenu > .ant-menu-item:hover {
  border-bottom: 2px solid #d90000;
}

.ant-menu-vertical .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-vertical-left .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-vertical-right .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-inline .ant-menu-item.menu-list:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-submenu.menu-list .ant-menu-submenu-title > div {
  white-space: pre-line;
}
.user-management-wrapper {
  height: 100% !important;
  flex-direction: column;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.user-management-wrapper .profileImage-wrapper {
  text-align: center;
}

.user-management-wrapper .profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.user-management-wrapper .ant-upload-select-picture {
  border-radius: 50%;
}

.user-management-wrapper .ant-upload-list-item-uploading {
  display: none;
}

.user-management-wrapper .ant-form-item-control {
  line-height: 30px;
}

.user-management-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}
.is-inactive {
  background-color: #ffefef !important;
}
.is-inactive:hover {
  background-color: #ffefef !important;
}

.email-link-button {
  padding: 0 !important;
}

.user-management-wrapper .user-filter {
  flex-direction: row-reverse;
  padding: 16px;
}

.user-management-wrapper .user-filter .ant-select-selection {
  width: 150px;
}

.user-management-wrapper .user-filter .search-input {
  width: 180px;
}

.user-management-wrapper .filter-divider {
  margin: 0;
}

.user-management-wrapper .user-list {
  margin: 0 20px;
  height: calc(100% - 64px - 15px);
  overflow: auto;
  padding: 12px;
  padding-left: 2px;
  flex: 1 1;
}

@media (max-width: 768px) {
  .user-management-wrapper .user-list div:first-child {
    justify-content: center;
  }
}

.user-management-wrapper .user-card-wrapper {
  margin-bottom: 2em;
  padding: 0 12px;
}

.user-management-wrapper .user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 8px;
  row-gap: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  padding: 8px;
  background: #fff;
  position: relative;
}

.user-management-wrapper .user-card .sync-plan-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
}

.user-management-wrapper .user-card .user-avatar {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.user-management-wrapper .user-card .user-avatar .ant-avatar-string {
  font-size: 2rem;
}

.user-management-wrapper .user-card .user-name {
  font-weight: bold;
  font-size: 22px;
  margin-right: 8px;
  vertical-align: middle;
}

.user-management-wrapper .user-card .user-role,
.user-management-wrapper .user-card .user-role:hover,
.permission-modal-header .user-role,
.permission-modal-header .user-role:hover {
  color: #666666 !important;
  background-color: #d9d9d9 !important;
  border: 1px solid #595959 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  height: 20px;
}

.user-management-wrapper .user-card .user-activity-details {
  padding: 0 1em;
  color: #666666;
  font-size: 10px;
}

.user-management-wrapper .user-card .user-activity-details .status-date {
  font-size: 11px;
}

.user-card .user-activity-details .ant-divider-vertical {
  height: 2em;
}

.user-management-wrapper .user-card .user-action-details {
  border: 1px solid #c4c4c4;
  padding: 4px 10px;
  width: 95%;
  color: #666666;
}

.user-management-wrapper .user-card .user-action-details .user-action-divider {
  color: #c4c4c4;
  font-size: 18px;
}

.user-management-wrapper .user-card .user-action-details > span {
  padding: 0 1em;
}

.user-management-wrapper .user-card .ant-divider-horizontal {
  margin: 0;
}

.permission-link-button {
  padding: 0 !important;
}

.permission-modal-header {
  margin-right: 20px;
  margin-top: 24px;
}

.permission-table .ant-table-tbody td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.user-permission-form .ant-select#user_permissions_templateId {
  max-width: 250px;
}

.user-management-wrapper .user-list .user-more-loader-wrapper {
  position: fixed;
  bottom: 5em;
  left: 55%;
  z-index: 999;
}

.permission-table .ant-form-item.d-none {
  display: none !important;
}

.permission-modal-header .user-details {
  align-items: flex-end;
}

.permission-modal-header .user-details .user-name {
  font-size: 18px;
  padding: 2px 10px;
  word-break: break-word;
}

.permission-modal-header .title {
  font-size: 30px;
}

.moderation-filter-wrapper {
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.moderation-filter-wrapper .search-input {
  width: 250px;
}

.moderation-filter-wrapper .moderation-select-filter {
  padding: 0 12px;
}

.moderation-filter-wrapper .ant-select-selection {
  width: 150px;
}

.moderation-filter-wrapper .ant-calendar-picker,
.comment-page .ant-calendar-picker {
  width: 210px;
}

.moderation-wrapper > .ant-divider.ant-divider-horizontal {
  margin: 0;
}

.moderation-wrapper .ml-auto {
  margin-left: auto;
}

.post-list-wrapper {
  height: 100%;
  padding: 12px 0px 12px 24px;
  position: relative;
}

.post-list-wrapper .virtuoso {
  height: 100% !important;
  overflow-x: hidden;
  margin-right: 2px;
}

.post-list-wrapper .post,
.comment-page .post {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  padding: 12px;
  background: #fff;
  margin: 1px 12px 12px 1px;
}

.post.selected-post {
  border: 2px solid black;
}

.post-list-wrapper .post-list-spinner {
  font-size: 48px;
}

.comment-list-wrapper .post-list-spinner {
  font-size: 48px;
}

.post-list-wrapper .bottom-fetch-more-loader {
  position: absolute;
  bottom: 20px;
}

.reference-data-container .post-share-details {
  padding: 12px;
}

.comment-list-wrapper .bottom-fetch-more-loader {
  position: absolute;
  bottom: 20px;
}

.post .post-user-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  word-break: break-word;
}

.post .post-user-action-icons span {
  font-size: 12px;
  line-height: 15px;
}

.post .post-user-action-icons i {
  font-size: 14px;
}

.post .post-action-icons span {
  font-size: 10px;
}

.post .post-action-icons i {
  font-size: 12px;
}

.post .post-create-time {
  font-size: 12px;
  color: #c1bdbd;
}

.post .post-text {
  margin-top: 14px !important;
  color: #262626 !important;
  word-break: break-word !important;
}

.post .video-container {
  position: relative;
}

.post-video-modal .video-react {
  padding-top: 56.25% !important;
}

.post button.video-play-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  color: white;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
}

.post .video-play-button:hover {
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
}

.post .video-gif {
  width: 100%;
  max-height: 350px;
  object-fit: contain;
  background-color: black;
}

.post .post-page-media-container {
  max-width: 100%;
  max-height: 350px;
  min-height: 100%;
}

.post-page-media-container > img {
  max-width: 100%;
  min-width: 100%;
  max-height: 350px;
  min-height: 100%;
  object-fit: contain;
}

.post-page-media-container > .video-react {
  padding-top: 56.25% !important;
}

.post .reference-data-container {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  /* padding: 12px; */
}

.reference-data-container img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}

.reference-data-container .reference-data-details {
  padding: 4px 8px;
}

.reference-data-details .reference-data-title {
  font-weight: bold;
  font-size: "14px";
  margin-bottom: 0px;
}

.post-page-divider {
  margin: 12px 0 !important;
}

.comment-list-wrapper,
.post-edit {
  /* overflow: auto; */
  height: 100%;
  padding: 12px;
  margin-left: 12px;
  background: #fff;
  position: relative;
}

.post-edit #edit-post-form {
  overflow: auto;
  height: 100%;
  padding: 12px 12px 0px 6px;
  display: flex;
  flex-direction: column;
}

.post-edit-reference-data {
  padding: 20px;
  border: 1px dotted black;
  position: relative;
}

.post-edit-reference-remove-upload {
  position: absolute;
  top: -5px;
  right: 0px;
  font-size: 24px;
  color: #d90000;
}

.post-edit .edit-media-wrapper {
  position: relative;
  padding: 20px;
  border: 1px dotted black;
}

.post-edit .btn-media-remove {
  position: absolute;
  top: -5px;
  right: 0px;
  color: #d90000;
  z-index: 999;
  font-size: 24px;
}

#edit-post-form .ant-upload-list-item-progress .ant-progress-outer {
  display: none;
}

.comment-wrapper {
  height: 100%;
  padding: 12px 12px 0px 6px;
  margin-bottom: 12px;
}

.comment-wrapper .virtuoso {
  height: 100% !important;
}

.replay-wrapper {
  height: calc(100% - 75px);
  padding: 12px 12px 0px 6px;
  margin-bottom: 12px;
}

.replay-wrapper .virtuoso {
  height: 100% !important;
}

.comment-list-wrapper .replay-header {
  margin-bottom: 12px;
}

.comment-list-wrapper .comment,
.live-comment-list .comment {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  padding: 12px;
  margin: 12px 12px;
}

.comment .comment-text {
  margin-top: 14px;
  color: #262626;
  word-break: break-word !important;
}

.comment .moderator-action-wrapper {
  margin-top: 14px;
}

.comment .comment-media {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  margin-bottom: 12px;
}

.comment .star-wrapper {
  margin-right: 6px;
  color: #999999;
}

.comment .star-icon-colored {
  color: #ebd45c;
}

.comment .comment-action-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 6px;
  gap: 6px;
}

.comment .moderator-action-list {
  padding-left: 20px;
  color: #8c8c8c;
}

.comment .comment-moderation-icons span {
  font-size: 10px;
  line-height: 15px;
  color: #595959;
}

.comment .comment-create-time {
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.comment .comment-user-name {
  font-weight: bold;
  word-break: break-word !important;
}

.ml-auto {
  margin-left: auto;
}
.comment-page .moderation-filter-wrapper .ant-switch-checked {
  background-color: #d90000;
}

.comment-page > .ant-divider.ant-divider-horizontal.moderation-filter-divider {
  margin: 0;
}

.comment-page .comment-list {
  height: 100%;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
}

.comment-list .virtuoso {
  height: 100% !important;
  overflow-x: hidden;
  margin-right: 2px;
  padding: 2px;
}

.flex-direction-column {
  flex-direction: column;
}

.moderation-action-btn,
.post .post-hidden-action-btn {
  color: white !important;
  background-color: #595959 !important;
  border-color: #595959 !important;
}

.comment-list button {
  overflow: hidden !important;
}

.comment-action-btn-wrapper button {
  overflow: hidden;
}

.comment-box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 12px;
  background: #fff;
  margin: 1px 12px 12px 1px !important;
}

.comment-box.selected {
  border: 1px solid black;
}

.comment-page .comment-action-btn-container {
  grid-gap: 8px;
  gap: 8px;
  align-items: stretch;
}

.comment-box .comment-user-details {
  margin-top: auto;
  padding: 12px 0;
}

.comment-box .comment-text {
  color: #262626;
  word-break: break-word !important;
}

.comment-user-details .comment-user-name {
  font-weight: bold;
  word-break: break-word !important;
}

.comment-user-details .comment-create-date {
  color: #c1bdbd;
  font-size: 12px;
}

.comment-user-details .comment-actions-icons span,
.sidecomment .moderation-score-icon span,
.sidecomment .comment-actions-icons span,
.comment .comment-actions-icons span {
  font-size: 10px;
  line-height: 15px;
}

.comment-user-details .comment-actions-icons i,
.sidecomment .moderation-score-icon i,
.sidecomment .comment-actions-icons i .comment .comment-actions-icons i {
  font-size: 12px;
}

.comment .comment-actions-icons .replay-count-wrapper {
  font-size: 12px;
}

.comment-user-details .comment-actions-icons .star-icon-gray {
  color: gray;
}

.sidecomment .comment-create-date,
.comment .comment-create-date {
  margin-left: 5px;
  color: #c1bdbd;
  font-size: 12px;
}

.sidecomment .show-comment-details {
  display: block;
}

.sidecomment .hide-comment-details {
  display: none;
}

.moderation-tag-wrapper {
  flex-wrap: wrap;
  grid-gap: 6px;
  gap: 6px;
}

.moderation-tag-wrapper .ant-tag {
  font-size: 10px;
  line-height: 16px;
}

.comment-box .comment-media {
  max-width: 100%;
  width: 350px;
  max-height: 250px;
  object-fit: contain;
  object-position: left;
}

.comment-box .comment-media-wrapper {
  margin: 12px 0;
}

.bottom-fetch-more-loader {
  position: absolute;
  bottom: 30px;
}

.top-fetch-more-loader {
  position: absolute;
  top: 30px;
}

.bottom-fetch-more-loader .moderation-spinner-size,
.top-fetch-more-loader .moderation-spinner-size {
  font-size: 48px;
}

.comment-page .sidecomment {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  padding: 12px;
  margin-bottom: 12px;
}

.comment-page .parent-comment-wrapper {
  padding: 0 1em;
}

.parent-comment-wrapper .sidecomment {
  background-color: #f5f5f5 !important;
}

.comment-page .post-wrapper {
  background-color: white;
  overflow-y: auto;
  padding: 12px;
  margin-left: 12px;
  height: calc(100vh - 64px - 64px - 10px);
}

.post-wrapper .side-comment-list-wrapper {
  padding: 1em 12px;
}

.height-0 {
  height: 0 !important;
}

.side-comment-list-wrapper .comment.sidecomment {
  margin: 2px 12px 12px 2px;
}

.comment.sidecomment.is-deleted,
.comment.is-deleted,
.comment-box.is-deleted {
  background-color: #ffefef !important;
}

.live-post-wrapper {
  background-color: white;
  overflow-y: auto;
  padding: 1em;
  height: 100%;
}

.live-post-video-wrapper {
  position: relative;
}

.live-post-video-tag {
  position: absolute;
  top: 20px;
  left: 30px;
}

.live-comment-list {
  position: relative;
  height: calc(100vh - 350px);
  padding: 1em;
}

.live-comment-list .virtuoso {
  height: 100% !important;
  overflow-x: hidden;
}

.live-comments-search-input {
  margin-bottom: 1em;
}

.align-self-center {
  align-self: center;
}

.live-video-action-wrapper {
  padding-top: 1em;
  color: #595959;
}

.live-video-action-wrapper .ant-switch-checked {
  background-color: #d90000;
}

.moderation-filter-wrapper .go-live-header-btn,
.moderation-filter-wrapper .go-live-header-btn:active {
  background-color: #d90000 !important;
  color: #fff !important;
  border-color: #d90000 !important;
}

.flex-1 {
  flex: 1 1;
}

.height-100 {
  height: 100% !important;
}

.post .live-time {
  font-size: 12px;
  line-height: 15px;
}

.post .live-post-action-icon {
  font-size: 12px;
  line-height: 15px;
}

.comment-text.live-comment-text {
  color: #262626;
  word-break: break-word !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.align-self-flexstart {
  align-self: flex-start;
}

.post-video-modal .ant-modal-content .ant-modal-close-x i {
  margin-left: 1em;
  vertical-align: 0.7em;
} 
.login-wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-form-wrapper{
  padding: 50px;
  border-radius: 5px;
  background: #f2f2f2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  /* transform: translate3D(0,-1px,0) scale(1.02);
  transition: all .4s ease; */
}
.login-wrapper .login-form-wrapper .header{
  margin-bottom: 20px;
}
.login-wrapper .login-form-wrapper .body{
  margin-top: 20px;
  text-align: center;
}

#root {
  height: 100vh;
  font-family: "Montserrat";
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}
.content-wrapper.ant-layout-content {
  max-height: 100vh;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;
}
.fullscreen {
  height: 100%;
  background: #f0f3f5;
}
img {
  max-width: 100%;
}
.d-flex {
  display: flex;
}
.box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  /* margin-bottom: 1rem; */
  padding: 1rem;
  background: #fff;
}
.text-center {
  text-align: center;
}
.border-bottom {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0.5em;
}
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callback-wrapper {
  width: 100%;
}
.callback-wrapper .loader-wrapper {
  height: 100vh;
}
.ant-table-row:nth-child(even),
.ant-table-thead > tr > th {
  background: #eeeeee;
}
.ant-table-row:nth-child(odd) {
  background: #f6f6f6;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
  line-height: 1;
  margin-bottom: 16px;
}

/* EPISODE STAUS */
.ant-tag.IDLE {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag.DRAFT {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag.SCHEDULED,
.ant-tag.RECORDING,
.ant-tag.UPCOMING {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag.LIVE,
.ant-tag.OPEN,
.ant-tag.LIVE_FINISHED,
.ant-tag.CONNECTED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag.PUBLISHED {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag.UNPUBLISHED,
.ant-tag.DISCONNECTED,
.ant-tag.ENDED,
.ant-tag.ERROR {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
/* END - EPISODE STAUS */

.ant-calendar-time-picker-btn {
  text-transform: capitalize;
}
.ant-calendar-ok-btn {
  text-transform: uppercase;
}
/* Upload */
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  padding-left: 80px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 80px;
}
/* END - Upload */

/* FONT WEIGHT */
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
/* END - FONT WEIGHT */

/* 404 */
.wrapper-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.wrapper-404 .title-404 {
  font-size: 10rem;
  font-weight: 700;
}
.wrapper-404 .subtitle-404 {
  font-weight: 700;
  font-size: 200%;
  margin-bottom: 2rem;
  line-height: 1.2;
}
.wrapper-404 .description-404 {
  margin-bottom: 10rem;
}
/* END - 404 */

/* FullScreen */
.fullscreen-mode aside,
.fullscreen-mode .header {
  display: none;
}
.fullscreen-mode .fullscreen {
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: 0px !important;
  max-width: none !important;
  min-height: 0px !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-transform: none !important;
          transform: none !important;
  margin: 0px !important;
}
/* END - Fullscreen */

.break-word {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.m-2 {
  margin: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-5 {
  margin-top: 0.8rem;
}

.p-2 {
  padding: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.p-0 {
  padding: 0;
}

.line-height-1 {
  line-height: 1.5;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ant-tag.IN_PROGRESS {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag.SUCCEED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-page-header.breakword {
  display: flex;
}

.ant-page-header.breakword .ant-page-header-heading {
  width: calc(100% - 220px);
}

.ant-page-header.breakword .ant-page-header-heading-title {
  word-break: break-all;
  display: inline;
}

.text-align-left {
  text-align: left !important;
}

.ant-page-header.breakword {
  display: flex;
}

.ant-page-header.breakword .ant-page-header-heading {
  width: calc(100% - 220px);
}

.ant-page-header.breakword .ant-page-header-heading-title {
  word-break: break-all;
  display: inline;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.user-management-wrapper {
  height: 100% !important;
  flex-direction: column;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.user-management-wrapper .profileImage-wrapper {
  text-align: center;
}

.user-management-wrapper .profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.user-management-wrapper .ant-upload-select-picture {
  border-radius: 50%;
}

.user-management-wrapper .ant-upload-list-item-uploading {
  display: none;
}

.user-management-wrapper .ant-form-item-control {
  line-height: 30px;
}

.user-management-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}
.is-inactive {
  background-color: #ffefef !important;
}
.is-inactive:hover {
  background-color: #ffefef !important;
}

.email-link-button {
  padding: 0 !important;
}

.user-management-wrapper .user-filter {
  flex-direction: row-reverse;
  padding: 16px;
}

.user-management-wrapper .user-filter .ant-select-selection {
  width: 150px;
}

.user-management-wrapper .user-filter .search-input {
  width: 180px;
}

.user-management-wrapper .filter-divider {
  margin: 0;
}

.user-management-wrapper .user-list {
  margin: 0 20px;
  height: calc(100% - 64px - 15px);
  overflow: auto;
  padding: 12px;
  padding-left: 2px;
  flex: 1;
}

@media (max-width: 768px) {
  .user-management-wrapper .user-list div:first-child {
    justify-content: center;
  }
}

.user-management-wrapper .user-card-wrapper {
  margin-bottom: 2em;
  padding: 0 12px;
}

.user-management-wrapper .user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  padding: 8px;
  background: #fff;
  position: relative;
}

.user-management-wrapper .user-card .sync-plan-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
}

.user-management-wrapper .user-card .user-avatar {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.user-management-wrapper .user-card .user-avatar .ant-avatar-string {
  font-size: 2rem;
}

.user-management-wrapper .user-card .user-name {
  font-weight: bold;
  font-size: 22px;
  margin-right: 8px;
  vertical-align: middle;
}

.user-management-wrapper .user-card .user-role,
.user-management-wrapper .user-card .user-role:hover,
.permission-modal-header .user-role,
.permission-modal-header .user-role:hover {
  color: #666666 !important;
  background-color: #d9d9d9 !important;
  border: 1px solid #595959 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  height: 20px;
}

.user-management-wrapper .user-card .user-activity-details {
  padding: 0 1em;
  color: #666666;
  font-size: 10px;
}

.user-management-wrapper .user-card .user-activity-details .status-date {
  font-size: 11px;
}

.user-card .user-activity-details .ant-divider-vertical {
  height: 2em;
}

.user-management-wrapper .user-card .user-action-details {
  border: 1px solid #c4c4c4;
  padding: 4px 10px;
  width: 95%;
  color: #666666;
}

.user-management-wrapper .user-card .user-action-details .user-action-divider {
  color: #c4c4c4;
  font-size: 18px;
}

.user-management-wrapper .user-card .user-action-details > span {
  padding: 0 1em;
}

.user-management-wrapper .user-card .ant-divider-horizontal {
  margin: 0;
}

.permission-link-button {
  padding: 0 !important;
}

.permission-modal-header {
  margin-right: 20px;
  margin-top: 24px;
}

.permission-table .ant-table-tbody td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.user-permission-form .ant-select#user_permissions_templateId {
  max-width: 250px;
}

.user-management-wrapper .user-list .user-more-loader-wrapper {
  position: fixed;
  bottom: 5em;
  left: 55%;
  z-index: 999;
}

.permission-table .ant-form-item.d-none {
  display: none !important;
}

.permission-modal-header .user-details {
  align-items: flex-end;
}

.permission-modal-header .user-details .user-name {
  font-size: 18px;
  padding: 2px 10px;
  word-break: break-word;
}

.permission-modal-header .title {
  font-size: 30px;
}
